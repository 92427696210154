import React, { useEffect, useState } from 'react'
import Sidebar from '../../component/sidebar/Sidebar'
import Layout from '../../component/layout/Layout'
import ProductCard from '../../component/product/ProductCard'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBuilderData, fetchcityProduct, fetchProjectData } from '../../redux/ProductSlice'
import { fetchcatedata } from '../../redux/ProductSlice'
import Loader from '../../component/loader/Loader'
import { listSelectedData } from '../../redux/ProductSlice'

const Listing = () => {

  const [citygetData, setCityGetData] = useState([]);
  const { isLoading, error } = useSelector((state) => state.product);
  
  const productdata = useSelector((state) => state.product.products?.data);

  const getallfilterproducts = useSelector((state)=>state.product.filterproduct)
  const dispatch = useDispatch()

  const { city } = useParams(); 


  const searchfilterdata = useSelector((state)=>state.product.searchFilterdata)


  
  useEffect(() => {
    if(productdata){
     
      if (city) {
        // Check if city is a string or a number
        const getFilterValue = city.split('-');
       
        if (getFilterValue[0]==="0") {
          // city is a string (e.g., city name)
          dispatch(fetchcityProduct(getFilterValue[1]));  // Dispatch an action to fetch data for the city name
        } else if(getFilterValue[0]==="1"){
          // city is a number (e.g., city ID)
          
          dispatch(fetchProjectData(getFilterValue[1]));
          // Dispatch an action to fetch data for the city ID
          // dispatch(fetchProjectData(cityNumber));
        }else if(getFilterValue[0] === "2"){
          dispatch(fetchcatedata(getFilterValue[1]));
        }else if(getFilterValue[0] === "3"){
          dispatch(fetchBuilderData(getFilterValue[1]));
        }
          }else {
            // If no city is provided, fetch all products
            setCityGetData(productdata);
          }
    }
  }, [city, dispatch, productdata]);

  // useEffect(() => {
  //   if(productdata){
  //     debugger
  //     if (Number(city)) {
  //       const cityNumber = parseInt(city, 10);
  //       dispatch(fetchProjectData(cityNumber));
  //     } 
  //     else{
  //       // If no city is provided, fetch all products
  //       setCityGetData(productdata);
  //     }
  //   }
  // }, [city, dispatch, productdata]);

  const FcClearFilters = () =>{
    setCityGetData(productdata);
  }

  useEffect(() => {
    if (getallfilterproducts && getallfilterproducts.length > 0) {
      setCityGetData(getallfilterproducts);
    }
  }, [getallfilterproducts]);



  useEffect(() => {
    
    if (searchfilterdata && searchfilterdata.length > 0) {
      
      setCityGetData(searchfilterdata);
    }
  }, [searchfilterdata]);

  return (
    <>
       <Layout>
        <section className='listing-banner bg-property overlay'>
            <div className='content-banner'>
                <div className='container text-center'>
                    <h1>Project Listing</h1>
                </div>
            </div>
        </section>
        <section className='list-product my-5'>
            <Sidebar sidebarData = {productdata} />
            <div className="container">
              <div className='breadcrum d-flex justify-content-between align-items-center'>
                  <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li class="breadcrumb-item active" aria-current="page"><Link to="#">Search Result</Link></li>
                  </ol>
                  <button className='clearfitler' onClick={FcClearFilters}>Clear Filter</button>
              </div>
              {
                isLoading ? (
                  <Loader/>
                ) : (
                  <>
                    <span className="total-list-result">Showing {citygetData?.length <= 0 ? productdata?.length : citygetData?.length}  Results</span>
                    {
                      citygetData && citygetData.length > 0 ? (<ProductCard productdata={citygetData} isLoading = {isLoading} />) : (<ProductCard productdata={productdata} isLoading = {isLoading} />)
                    }
                  </>
                )
              }
              
            </div>

         {/* <ProductCard productdata={productdata} /> */}
 
        </section>
       </Layout>
    </>
  )
}

export default Listing