import React from 'react'
import { IoMdClose } from "react-icons/io";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const MapPopup = ({mapPopup, setMapPopup, singleData, mapUrl}) => {
    const productdata = useSelector((state) => state.product.products?.data);

   const closeMapPopup = () =>{
    setMapPopup(false)
   }

  return (
    <>
        <div className={mapPopup === true ? 'activemap mapPopup' : 'mapPopup'}>
            
           <div className='mapwrapper'>
                <button className='close-btn' onClick={closeMapPopup}>
                    <IoMdClose />
                </button>
                {/* <Link href={mapUrl} target="_blank" rel="noopener noreferrer">
                    Open Location in Google Maps
                </Link> */}
                <div className='mapdata'>
                  <iframe id="mapIframe" src={mapUrl} width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
           </div>
        </div>
    </>
  )
}

export default MapPopup