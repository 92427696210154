import React from 'react'
// import { HiArrowSmallRight } from "react-icons/hi2";
// import blog from '../../images/blog.jpg'
// import { Link } from 'react-router-dom';

const BlogCard = ({blogItem}) => {
  return (
    <>
        <div className='place-card mb-3'>
            <div className='place-image relative'>
                <img src={blogItem?.featured_image} alt='card'/>
            </div>
            <div className='place-area-content'>
                <h5>{blogItem?.title}</h5>
                {/* <div className='d-flex justify-content-end'>
                  <Link to="" className='read-more'>Read More <HiArrowSmallRight /></Link>
                </div> */}
            </div>
            
        </div>
    </>
  )
}

export default BlogCard