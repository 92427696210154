import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchGallery = createAsyncThunk('gallery', async()=>{
    const res = await fetch('https://omniinfraheights.com/admin/public/api/gallery')
    const data = await res.json();
    return data
})

const GallerySlice = createSlice({
    name:'Gell',
    initialState:{
        isLoading:false,
        gallery:[],
        isError:false,
    },
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(fetchGallery.pending, (state, action)=>{
            state.isLoading = true
        })
        builder.addCase(fetchGallery.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.gallery = action.payload
        })
        builder.addCase(fetchGallery.rejected, (state, action)=>{
            state.isError = true;
            state.gallery = action.payload
        })
    }
})


export default GallerySlice.reducer