import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchBlog = createAsyncThunk('blog', async () => {
    
    try {
        const res = await fetch('https://omniinfraheights.com/blog/wp-json/custom/v1/latest-posts');
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        const data = await res.json();
        return data;
    } catch (error) {
        console.error('Error fetching blog posts:', error);
        throw error; // Ensures error is propagated to the rejected state
    }
});
const BlogSlice = createSlice({
    name:'blog',
    initialState:{
        isLoading:false,
        blogs:[],
        isError:false,
    },
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(fetchBlog.pending, (state, action)=>{
            state.isLoading = true
        })
        builder.addCase(fetchBlog.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.blogs = action.payload
        })
        builder.addCase(fetchBlog.rejected, (state, action)=>{
            state.isError = true;
            state.blogs = action.payload
        })
    }
})


export default BlogSlice.reducer