import React from 'react'
import { Link } from 'react-router-dom'
import { MdLocalPhone } from "react-icons/md";
import { IoMdShare } from "react-icons/io";

const ProductCard = ({productdata}) => { 


  return (
    <>
           
        <div className='product-listing'>
        {
                
                productdata?.map((listItem)=>{
                    return(
                        <>
                            <div className='product-inner-list-card d-flex mb-3 px-3 py-3 mt-3' key={listItem.listingID}>
                                <div className='card-image'>
                                <img src={listItem && listItem?.image ? `https://omniinfraheights.com/admin/public/uploads/${listItem.image[0]}` : 'fallback-image-url'} alt='card' />
                                    
                                </div>
                                <div className='list-card-content'>
                                    <h3 className='d-flex align-items-center justify-content-between'>
                                        <span>
                                            <Link to={`/detail/${listItem.listingID}`}>
                                                {listItem.title}
                                            </Link>
                                        </span> 
                                        <IoMdShare />
                                    </h3>
                                    
                                    <span className='location-title'>{listItem.address}, {listItem.city}</span>
                                    <div className='price-area d-flex mt-4'>
                                        <div className='price-item inn-item'>
                                            <span>price</span>
                                            <h4> {listItem.price}</h4>
                                        </div>
                                        <div className='area inn-item'>
                                            <span>Area</span>
                                            <h4>{listItem.size}</h4>
                                        </div>
                                        <div className='bhk-area inn-item'>
                                            <span>BHK</span>
                                            <h4>{listItem.unit_type}</h4>
                                        </div>
                                    </div>
                                    <div className='desc mt-4'>
                                    <div className='mb-0' dangerouslySetInnerHTML={{ __html: listItem.description }}></div>

                                        <Link to={`/detail/${listItem.listingID}`}>Details</Link>
                                    </div>
                                    <div className='builder mt-4 pt-4 d-flex align-items-center justify-content-between'>
                                        <div>
                                            <div className='builder-about'>
                                                <span>Builder</span>
                                                <h5>{listItem.builder_name}</h5>
                                            </div>
                                            <hr/>
                                            <div className='builder-about'>
                                                <span>Project</span>
                                                <h5>{listItem.project_name}</h5>
                                            </div>
                                        </div>
                                        <Link to="/contact"><MdLocalPhone /> Contact</Link>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
                
            }
        </div>
    </>
  )
}

export default ProductCard