import React, { useRef, useState } from 'react'
import bannerIn from "../../images/banner.jpg"
import Layout from '../../component/layout/Layout'
// import { toast } from 'react-toastify'
import career from '../../images/careers-banner-desktop.webp'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2';
// import emailjs from '@emailjs/browser';

const Career = () =>{

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    phone: "",
    subject: "",
    attachment: null,
  });

  console.log('formData', formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, attachment: e.target.files[0] }));
  };

  const handleSubmit = async (e) =>{

    debugger
    e.preventDefault();

    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("name", formData.name);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("subject", formData.subject);
    if (formData.attachment) {
      formDataToSend.append("attachment", formData.attachment);
    }

    console.log([...formDataToSend.entries()]);

    try {
      const response = await fetch("https://omniinfraheights.com/admin/public/api/career-mail", {
        method: 'POST',
        body: formDataToSend
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Thank you',
          text: 'Your form has been submitted successfully!',
          confirmButtonText: 'OK',
        });
        setFormData({email:"",name: "",phone: "",subject: "",attachment: '',})
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong while submitting the form.',
          confirmButtonText: 'Try Again',
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong while submitting the form.',
        confirmButtonText: 'Try Again',
      });
    }
  }


    return(
        <>
       <Layout>
        <section className='banner bg-property inner_banner'>
                <div className='inner-banner-image'>
                <img src={bannerIn} alt='banner-inn' />
                <div className='banner-content'>
                    <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                        <h1 className='mb-3'>Career</h1>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        </section>
        <section className='about-content py-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='contact-form'>
                  <div className='register'>
                    <div className='reg-inn'>
                      <h2>Recruitment</h2>
                      <p>Let’s start something big together!</p>
                        <form  onSubmit={handleSubmit} method="POST">
                            <div className='formgroup'>
                            <label htmlFor="name">Name</label>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                                {/* {error.name && <span className='red'>{error.name}</span>} */}
                            </div>
                            <div className='formgroup'>
                            <label htmlFor="email">Email</label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                                {/* {error.email && <span className='red'>{error.email}</span>} */}
                            </div>
                            <div className='formgroup'>
                              <label htmlFor="phone">Phone Number:</label>
                              <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                              />
                                {/* {error.phone && <span className='red'>{error.phone}</span>} */}
                            </div>
                            <div className='formgroup'>
                              <label htmlFor="subject">Job Title:</label>
                              <input
                                type="text"
                                id="subject"
                                name="subject"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className='formgroup'>
                              <label htmlFor="attachment">Select your resume (PDF, DOC, DOCX):</label>
                              <input
                                type="file"
                                id="attachment"
                                name="attachment"
                                accept=".pdf, .doc, .docx"
                                onChange={handleFileChange}
                                required
                              />
                            </div>
                            <div className='submit-btn d-flex justify-content-center'>
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                  </div>
              </div>
            </div>
            <div className='col-lg-6'>
            {/* <div className='contact_info pt-4'>
              <h6><strong>Location - </strong>Agra (Head Office)</h6>
              <p><strong>Address - </strong> 9, Suraj complex , Sadar Bazar Agra, U.P. 282001</p>
              <h6><strong>Phone - </strong><a href='tel:+91 9837038701'>+91 9837038701</a></h6>
              <h6><strong>Email - </strong> <a href='mailto:info@omniinfraheights.com'>info@omniinfraheights.com</a></h6>
              <p className='mt-3'><strong>No.</strong>  UPRERAAGT15736</p>
            </div> */}
              <div className='img-item mt-4'>
               <img src={career} alt='pic' />
              </div>
            </div>
          </div>
        </div>
      </section>
       </Layout>
        </>
    )
}

export default Career;