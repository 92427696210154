import { configureStore } from "@reduxjs/toolkit";
import CategorySlice from "./CategorySlice";
import popupReducer from './PopupSlice';
import ProductReducer from './ProductSlice'
import searchReducer from "./SearchSlice"
import loaderReducer from './LoaderSlice'
import builderReducer from './BuilderSlice'
import projectReducer from './ProjectSlice'
import galleryReducer from './GallerySlice'
import blogReducer from './BlogSlice'


const Store = configureStore({
    reducer:{
    category:CategorySlice,
    popup: popupReducer,
    product:ProductReducer,  
    searchfilter:searchReducer,   
    loader:loaderReducer,
    owner:builderReducer,
    proj:projectReducer,
    Gall:galleryReducer,
    blog:blogReducer,
    }
    
})

// export const persistor = persistStore(Store);
export default Store