import React from 'react'
import bannerIn from "../../images/banner.jpg"
import Layout from '../../component/layout/Layout'
import aboutImage from '../../images/about.jpg'

const About = () => {
  return (
    <>
      <Layout>
      <section className='banner bg-property inner_banner'>
        <div className='inner-banner-image'>
          <img src={bannerIn} alt='banner-inn' />
          <div className='banner-content'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-7 mx-auto'>
                  <h1 className='mb-3'>About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='about-content py-4'>
        <div className='container'>
        <div className='content'>
            <h3 className='mb-4'>Mission</h3>
            <h5>Where Certainty Becomes Reality</h5>
              <p>At Omni Infra Heights, our mission is to redefine the real estate industry by placing “where certainty becomes reality” at the heart of everything we do. We understand that buying or selling a property is a significant life decision, and our clients deserve unwavering confidence throughout their journey with us.</p>
              <p>With a deep-rooted commitment to experience certainty, we strive to provide a seamless and transparent real estate experience for our valued clients. We believe that knowledge is power, and we equip our team with extensive expertise and up-to-date market insights to ensure that our clients are well-informed and empowered to make informed decisions.</p>
              <p>At the core of our mission is a customer-centric approach, where we listen attentively to our clients' needs, concerns, and aspirations. By understanding their unique goals, we tailor our services to exceed their expectations, leaving no room for uncertainty in the process.</p>
              <p>We embrace cutting-edge technology and innovative tools to streamline our operations, making the real estate journey efficient and stress-free. Our dedicated team of professionals is committed to anticipating challenges and proactively solving them, ensuring a smooth and hassle-free experience for every client we serve.</p>
              <p>Beyond delivering exceptional results, we are devoted to building lasting relationships based on trust, integrity, and open communication. Omni Infra Heights strives to be a dependable partner, providing support and guidance even after the transaction is complete.</p>
              <p>With experience certainty as our guiding principle, we aspire to set new standards of excellence in the real estate industry. Our mission is to create a legacy of satisfied clients, who can confidently attest that Omni Infra Heights is synonymous with reliability, assurance, and success in their real estate endeavors.</p>
              <p>Together, let us soar to new heights with Omni Infra Heights, where certainty takes real meaning is the key to unlocking your dream property and a brighter future.</p>
              <h3 className='mt-4'>Vision</h3>
              <p>To provide customers with unparalleled sales and purchase experience in real estate , by delivering a seamless and transparent experience that instills confidence, reliability, and certainty throughout the entire process.</p>
          
              <h3 className='mt-4'>Core Values</h3>
              <ul className="custom-list">
                <p>HUMBLE. We never forget that we owe everything to the efforts of each team member, our families, and our clients</p>
                <p>CUSTOMER CENTRICITY. Relentlessly trying to follow our customers' dreams and wishes to fulfill them.</p>
                <p>GRIT. We persevere in our efforts no matter the obstacle with a high sense of urgency and always keeping our team and clients top of mind.</p>
                <p>INTEGRITY. We promise to always do the right thing for our team, our business, and our clients, resulting in mutual success.</p>
                <p>SERVANT LEADERSHIP. We are passionate about giving back in the communities we serve.</p>
                <p>ACCOUNTABLE. We operate with a high level of accountability, taking full ownership in delivering on our commitment to excellence.</p>
                <p>INNOVATIVE. We pursue excellence by pushing each other to be better every day and seeing possibility instead of limitation.</p>
                <p>SOLUTION-BASED. We are resourceful, always seeking to discover a solution and providing options for any concerns that arise.</p>
              </ul>
            </div>
        
        </div>
        <div className="container">            
            <div className="row">
              <div className="col-md-8">
                <div className="about-image">
      

                <h3>Our Founder – [Mr. Praveen Pathak]</h3>
              <ul className="custom-list">
                <li>As the founder of Omni Infra Heights, Mr. Praveen Pathak brings a wealth of knowledge, passion, and dedication to the world of real estate. With over 25 years of experience in the industry, Mr. Praveen Pathak is known for combining a deep understanding of market trends with an unwavering commitment to helping clients achieve their real estate goals.</li>
                <li>Mr. Praveen Pathak’s journey into real estate began with a simple belief: that the process of buying, selling, or investing in property should be transparent, personalized, and rewarding. With a background in Reality consultant , property management , Construction , Architect . Mr. Praveen Pathak developed a keen eye for identifying value and a strong understanding of the intricacies of the real estate market.</li>
                <li>Over the years, Mr. Praveen Pathak has built a reputation as a trusted advisor to clients, offering guidance through every step of the process. Whether it’s finding the perfect home, securing the best investment opportunities, or navigating complex transactions, Mr. Praveen Pathak’s approach is always client-centered, focused on building lasting relationships and delivering results.</li>
                
              </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about-image">
                <img src={aboutImage} alt="About" style={{ width: '100%', height: '100%' }} />
               
                </div>
              </div>


            </div>
        </div>
      </section>
      </Layout>
    </>
  )
}

export default About