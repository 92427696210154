import { useSelector } from "react-redux"
import Layout from "../../component/layout/Layout"
import bannerIn from "../../images/banner.jpg"
import {SlideshowLightbox} from 'lightbox.js-react'


const Gallery = () =>{

const getgally = useSelector((state)=> state.Gall.gallery?.data)
console.log('getgally', getgally)


    return(
        <>
            <Layout>
            <section className='banner bg-property inner_banner'>
                <div className='inner-banner-image'>
                <img src={bannerIn} alt='banner-inn' />
                <div className='banner-content'>
                    <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 mx-auto'>
                        <h1 className='mb-3'>Galleries</h1>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <section className="gallery-section py-4">
            
               <div className="container">
                     
                    <div className="row">
                        {
                            getgally && 
                            getgally?.map((item)=>{
                                return(
                                    <>
                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3" key={item.id}>
                                        <SlideshowLightbox className="gallery-card">
                                            <img className="rounded" src={`https://omniinfraheights.com/admin/public/${item.image_path}`} alt="pic"/>
                                        </SlideshowLightbox> 
                                    </div>
                                    
                                    </>
                                )
                            })
                        }
                    </div>
                        
                  
                    
                </div>
            </section>
            </Layout>
        </>
    )
}

export default Gallery