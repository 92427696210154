import React, { useEffect, useState } from 'react'
import Layout from '../../component/layout/Layout'
import { Link, useParams } from 'react-router-dom'
import { FaLocationDot } from "react-icons/fa6";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import project1 from '../../images/project1.webp'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleProduct } from '../../redux/ProductSlice';
import { toast } from 'react-toastify';
import DOMPurify from "dompurify";
import MapPopup from '../../component/MapPopup';
import { showPopup } from '../../redux/PopupSlice';
import ScrollToTop from '../../component/scrolltop/ScrollToTop';

const Detail = () => {

  const [mapPopup, setMapPopup] = useState(false)

  const [userdata, setUserData] = useState({
    sourcetype:"Twitter", name:"", email:"", phone:"", address:""
   })
  
  const {id} = useParams()

  console.log('id', id)
  const dispatch = useDispatch()

  const singleData = useSelector((state) => state.product.singleProduct);
  const productdata = useSelector((state) => state.product.products?.data);
  console.log('singleData', singleData)
 
  useEffect(() => {
    dispatch(fetchSingleProduct(id));
}, [dispatch, id]);


 const handleinput = (e) =>{
   const {name, value} = e.target
   setUserData({...userdata, [name]:value})   
}

const [error, setError] = useState({})

const handleSubmit = async(e) =>{
 e.preventDefault()

 const errorMsg = {}
 // localStorage.setItem("user", JSON.stringify(userdata));
 // navigate('/')


 if(userdata.name === '' || userdata.name === 'null'){
     errorMsg.name = 'name is required'
 }
 if(userdata.email === '' || userdata.email === 'null'){
     errorMsg.email = 'email is required'
   }else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userdata.email)){
     
     errorMsg.email = 'Please enter a valid email'
 }
 if(userdata.phone === '' || userdata.phone === 'null'){
     errorMsg.phone = 'phone number is required'
 }

 setError(errorMsg)

 if(Object.keys(errorMsg).length === 0){
  let toastId; // Define the toastId variable here
     try {
      toastId = toast("Submitting...", { autoClose: false });
         const response = await fetch('https://omniinfraheights.com/admin/public/api/create-query', {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json'
           },
           body: JSON.stringify(userdata)
         });
   
         if (!response.ok) {
           throw new Error('FAILED TO CREATE USER');
         }
   
         const getdata = await response.json();
         console.log('User created successfully:', getdata);
         toast.update(toastId, {
          render: "User created successfully!",
          type: "success",
          autoClose: 3000,
          isLoading: false
        });
         setUserData({
             sourcetype:"", name:"", email:"", phone:"", address:""
         });

       } catch (error) {
         console.error('Error creating user:', error.message);
         toast.update(toastId, {
          render: `Error: ${error.message}`,
          type: "error",
          autoClose: 3000,
          isLoading: false
      });
       }
   }else {
    // Show validation errors as toast
    Object.values(errorMsg).forEach(err => {
        toast.error(err);
    });
  }
}

const handleMapPopup = () =>{
  setMapPopup(!mapPopup)
}

const handleScroll = () =>{
  <ScrollToTop/>
}

const longitude = singleData.data?.longitude
const latitude = singleData.data?.latitude

const mapUrl = `http://www.google.com/maps/place/${longitude},${latitude}`;

const sanitizedHTML = DOMPurify.sanitize(singleData.data?.amenities);
const sanitizedHTMLDesc = DOMPurify.sanitize(singleData.data?.description);

  return (
    <Layout>
        <section className='listing-banner bg-property overlay'>
            <div className='content-banner'>
                <div className='container text-center'>
                    <h1>{singleData.data?.title}</h1>
                </div>
            </div>
        </section>
        <section className='detail mt-100'>
          <div className='container'>
            <div className="row">
              <div className='col-lg-8'>
                <div className='breadcrum'>
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li class="breadcrumb-item"><Link to="/listing">listing</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">detail</li>
                  </ol>
                </div>
                <div className='detail-heading mt-5'>
                  <h1 className='mb-3'>{singleData.data?.title}</h1>
                  <span><FaLocationDot />{singleData.data?.address}</span>
                  <h3>{singleData.data?.price}</h3>
                </div>
                <div className='detail-slider mt-3'>
                  <Swiper
                      modules={[Navigation, Autoplay]}
                      spaceBetween={20}
                      slidesPerView={1}
                      loop={true}
                      speed={1200}
                      autoplay={true}
                    
                      navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                      >
                      {
                        singleData.data?.image?.map((ImgItem, index)=>{
                          return(
                            <>
                              <SwiperSlide key={index}>
                                  <div className='slider-image'>
                                  <img src={singleData.data && singleData.data?.image ? `https://omniinfraheights.com/admin/public/uploads/${ImgItem}` : 'fallback-image-url'} alt='card' />
                                  {/* <img src={`https://ithike.com/omni/dashboard/public/${ImgItem}`} alt='banner' /> */}
                                    
                                  </div>
                              </SwiperSlide>
                            </>
                          )
                        })
                      }
                      
                      
                  </Swiper>
                  <div className='swiper-nav'>
                      <button className="arrow-right arrow"><GoArrowLeft /></button>
                      <button className="arrow-left arrow"><GoArrowRight /></button>
                  </div>
                </div>
                <div class="w-100 padding projectDetails">
                    <div class="row gap-row">
                        <div class="col-lg-4 col-md-4 section-title">
                            <h5 class="mb-20">RERA Registration</h5>
                        </div>
                        <div class="col-lg-8 col-md-8 section-details">
                            <div class="inner">
                                <span class="mb-0 mt-3">{singleData.data?.area_no}</span><br />
                              
                            </div>
                        </div>
                    </div>
                    <div class="row gap-row">
                        <div class="col-lg-4 col-md-4 section-title">
                            <h5 class="mb-0">Approved By :</h5>
                        </div>
                        <div class="col-lg-8 col-md-8 section-details">
                            <div class="inner">
                                <span class="mb-0 mt-3">{singleData.data?.approvals}</span><br />
                                <Link to="http://maharera.mahaonline.gov.in" target="_blank" className="d-none">http://maharera.mahaonline.gov.in</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 padding projectDetails">
                    <div class="row gap-row">
                        <div class="col-lg-4 col-md-4 section-title">
                            <h5 class="mb-0">Description</h5>
                        </div>
                        <div class="col-lg-8 col-md-8 section-details">
                            <div class="inner" dangerouslySetInnerHTML={{ __html: sanitizedHTMLDesc }}>
                                {/* <p>{singleData.data?.description.replace(/<\/?p>/g, "")}</p> */}
                                {/* <Link to="/home/detail/" className='custom-btn'>Read More</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 padding projectDetails amenities">
                    <div class="row gap-row">
                        <div class="col-lg-4 col-md-4 section-title">
                            <h5 class="mb-0">Amenities</h5>
                        </div>
                        <div class="col-lg-8 col-md-8 section-details">
                            <div class="inner" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}>
                        
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 padding projectDetails">
                  <div class="row gap-row">
                      <div class="col-lg-4 col-md-4 section-title">
                          <h5 class="mb-0">Floor Plans</h5>
                      </div>
                      <div class="col-lg-8 col-md-8 section-details">
                          <div class="inner">
                              <div class="fpContainer">
                                <div class="fpBox">
                                  <div class="form-row gap-form-row align-items-center">
                                      <div class="col insideBox">
                                          <div class="heading mb-0">
                                              <h6 class="mb-0">{singleData.data?.unit_type}</h6>
                                          </div>
                                      </div>
                                      <div class="col insideBox">
                                          <p class="mb-0">{singleData.data?.size}</p>
                                      </div>
                                      <Link to={mapUrl} target='_blank' className='custom-btn'><FaLocationDot /> View on Map</Link>
                                  </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="w-100 padding projectDetails">
                  <div class="row gap-row">
                      <div class="col-lg-4 col-md-4 section-title">
                          <h5 class="mb-0">FAQs</h5>
                      </div>
                      <div class="col-lg-8 col-md-8 section-details">
                          <div class="inner">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                  Is Purvanchal Royal City RERA-approved?  
                                  </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                    <p>Ans: Yes, the project is approved by UPRERA and holds a registration number.</p>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                  What are the units available for sale in Purvanchal Royal City, Greater Noida? 
                                  </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                    <p>Ans: There are 3 BHK, 4 BHK, and 5 BHK apartments in Purvanchal Royal City for sale.</p>
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                  Is commutation easy from the gated community in Greater Noida? 
                                  </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">
                                    <p>Ans: Absolutely yes, the metro connectivity and road network ease commutation from Purvanchal Royal City.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                  </div>
                </div>

              </div>
              <div className='col-lg-4'>
                <div className='about-detail'>
                  <h2>Price on Request</h2>
                  <div className='formcontainer mt-3'>
                      <div className='contact-form'>
                        <div className='register'>
                          <div className='reg-inn'>
                              <form onSubmit={handleSubmit}>
                                  <div className='formgroup'>
                                      <label htmlFor='name'>Name</label>
                                      <input type='text' onChange={handleinput} name='name' value={userdata.name} />
                                      {error.name && <span className='red'>{error.name}</span>}
                                  </div>
                                  <div className='formgroup'>
                                      <label htmlFor='email'>Email</label>
                                      <input type='email' onChange={handleinput} name='email' value={userdata.email} />
                                      {error.email && <span className='red'>{error.email}</span>}
                                  </div>
                                  <div className='formgroup'>
                                      <label htmlFor='phone'>Phone</label>
                                      <input type='phone' onChange={handleinput} name='phone' value={userdata.phone} />
                                      {error.phone && <span className='red'>{error.phone}</span>}
                                  </div>
                                  <div className='formgroup'>
                                      <label htmlFor='address'>Address</label>
                                      <textarea name="address" onChange={handleinput} value={userdata.address} rows="2" cols="20"></textarea>
                                  </div>
                                  <div className='submit-btn d-flex justify-content-center'>
                                      <button type="submit">Submit</button>
                                  </div>
                              </form>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className='top-rated-project'>
                  <h4>Top Rated Projects</h4>

                      {
                        productdata?.map((item, index)=>{
                          return(
                            <>
                              <div className='inner d-flex align-items-center mb-4' key={index}>
                                  <div className='img-fluid'>
                                    {
                                      item && item.image && item.image[0] ? (
                                        <img 
                                          src={`https://omniinfraheights.com/admin/public/uploads/${item.image[0]}`} 
                                          alt='card' 
                                        />
                                      ) : (
                                        <img src={project1} alt='fallback' />
                                      )
                                    }
                                  
                                  </div>
                                  <div className='boxDetails'>
                                    <Link onClick={handleScroll} to={`/detail/${item.listingID}`}>{item?.title}</Link>
                                    <p> <span className="text-green">{item?.price}</span></p>
                                  </div>
                              </div>
                            </>
                          )
                        })
                      }

                </div>
                </div>
                
              </div>
            </div>
          </div>
        </section>
        <MapPopup mapPopup = {mapPopup} setMapPopup = {setMapPopup} singleData ={singleData} mapUrl={mapUrl} />

    </Layout>
  )
}

export default Detail